body {
  background: linear-gradient(45deg, #ff7e5f, #feb47b, #ff6f69, #ffcc5c, #88d8b0);
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease infinite;
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.App {
  text-align: center;
  margin-top: 50px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

input {
  padding: 10px;
  margin-right: 10px;
  border: 2px solid #008cba;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
}

button {
  padding: 10px 15px;
  margin-left: 5px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button.add-task {
  background-color: #4caf50;
  color: white;
}

button.add-task:hover {
  background-color: #45a049;
}

ul {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
}

li {
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

li:hover {
  transform: scale(1.02);
}

li span {
  flex-grow: 1;
  cursor: pointer;
  font-size: 18px;
}

li span.completed {
  text-decoration: line-through;
  color: #999;
}

li button {
  margin-left: 10px;
  background-color: #f44336;
  color: white;
}

li button.edit {
  background-color: #008cba;
}

li button:hover {
  opacity: 0.8;
}
